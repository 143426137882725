.landing{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
}

.title img{
    position: absolute;
}

.blob1{
    top: 50%;
    left: 0%;
    transform: translate(5%, -40%);
    animation: rotation1 8s infinite linear;
    animation-play-state: paused;
}

.blob1:hover{
    animation-play-state: running;
}

.blob2{
    top: 0%;
    left: 50%;
    transform: translate(-50%, 50%);
    animation: rotation2 8s infinite linear;
    animation-play-state: paused;
}
.blob2:hover{
    animation-play-state: running;
}

.blob3{
    top: 0%;
    right: 0%;
    transform: translate(-10%, 40%);
    animation: rotation3 8s infinite linear;
    animation-play-state: paused;
}
.blob3:hover{
    animation-play-state: running;
}

.blob4{
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -20%);
    animation: rotation4 8s infinite linear;
    animation-play-state: paused;
}
.blob4:hover{
    animation-play-state: running;
}

.title h1{
    color: var(--gBlack);
    font-weight: 300;
    font-size: 3rem;
    word-spacing: 3px;
    z-index: 2;
}

.title h1 span{
    font-weight: 600;
}

.scroll{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 20px;
    font-size: 1.2rem;
    word-spacing: 2px;
}

.scroll p{
    margin: 5px 0;
    z-index: 2;
}

.arrow{
    animation: jump infinite 2s;
}

.steps{
    margin-bottom: 100px;
    display: grid;
    padding: 20px 50px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    align-items: center;
}

.steps img{
    width: 80%;
}
.steps img:nth-child(odd){
    justify-self: right;
}

.number{
    width: 60px;
    height: 60px;
    margin: 30px;
    border-radius: 60px;
    background-color: var(--gBlackDark);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
}

.stepText{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.stepText h2{
    width: 60%;
    font-size: 1.3rem;
    font-weight: 400;
}

.stepText:nth-child(odd){
    justify-self: right;
    text-align: right;
}

.cta{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--gBlack);
    margin-bottom: 50px;
}
.cta h2{
    font-weight: 400;
    word-spacing: 2px;
}
.cta h2 span{
    font-weight: 700;
}
.cta a{
    color: var(--brandBlue);
    font-weight: 600;
    font-size: 1.3rem;
}
.underline{
    width: 100%;
    height: 4px;
    background-color: var(--gYellow);
}
@keyframes jump{
    0%{
        transform: translateY(-5px);
    }
    50%{
        transform: translateY(5px);
    }
    100%{
        transform: translateY(-5px);
    }
}

@keyframes rotation1 {
    from {
    transform: translate(5%, -40%) rotate(0deg);
    }
    to {
      transform: translate(5%, -40%) rotate(359deg);
    }
}

@keyframes rotation2 {
    from {
    transform: translate(-50%, 50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, 50%) rotate(359deg);
    }
}

@keyframes rotation3 {
    from {
    transform: translate(-10%, 40%) rotate(0deg);
    }
    to {
      transform: translate(-10%, 40%) rotate(359deg);
    }
}

@keyframes rotation4 {
    from {
    transform: translate(-50%, -20%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -20%) rotate(359deg);
    }
}

