.nav{
    display: flex;
    flex-direction: row;
    width: calc(100% - 96px);
    align-self: baseline;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    padding-left: 64px;
    background-color: white;
    color: var(--brandBlue);
    position: fixed;
    top: 0;
    z-index: 100;
}

.logoName{
    font-weight: 700;
    margin: 0;
    margin-right: 50px;
    cursor: pointer;
    font-size: 1.8rem;
}

.linksGroup{
    display: flex;
    flex-direction: row;
    font-weight: 600;
    font-size: 1.2rem;
}

.linksGroup a{
    margin: 0 16px;
}

.centreLinks
{
    color: var(--gGrey);
    margin-left: 50px;
}