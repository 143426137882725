.footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--gBlack);
    padding: 12px;
    margin-top: 20px;
    color: white;
}
.footer p{
    margin: 0;
    word-spacing: 3px;
}
.footer a{
    display: flex;
    align-items: center;
}
.footer img{
    height: 1rem;
    margin: 0 5px;
}